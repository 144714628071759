
import { reactive, ref, toRefs, defineComponent, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElForm, ElMessage } from "element-plus";
import { forgetPassword } from "@/api/login";
import { sendCode } from "@/api/login";

type FormInstance = InstanceType<typeof ElForm>;
export default defineComponent({
  name: "forgetPassword",

  setup({}, { emit }) {
    const router = useRouter();
    const activeName = ref("aa");
    const isShowSend = ref(true);
    const timeNum = ref(60);
    const countDown = ref();
    const forget = ref<FormInstance>();
    const isAffirm = ref(false);

    const forgetRuleForm = reactive({
      mobile: "",
      code: "",
      password: "",
      password_confirmation: "",
    });

    const vaildateMobile = (rule: any, value: any, callback: any) => {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const forgetRules = reactive({
      mobile: [
        {
          required: true,
          message: "请输入手机号",
          trigger: "blur",
        },
        { validator: vaildateMobile, trigger: "change" },
      ],
      password: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
      ],
      code: [
        {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
        },
      ],
      password_confirmation: [
        {
          required: true,
          message: "请再次输入密码",
          trigger: "blur",
        },
      ],
    });
    // 立即重置
    const submit = (formEl: FormInstance | undefined): void => {
      (formEl as any).validate((valid: any) => {
        if (valid) {
          forgetPassword(forgetRuleForm).then((res: any) => {
            if (res.code == 0) {
              ElMessage.success("密码重置成功");
              if (windowW > 850) {
                emit("switch", "login");
              } else {
                router.push({ name: "login" });
              }
            }
          });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };

    // 发送验证码
    const send = (): void => {
      sendCode({ mobile: forgetRuleForm.mobile }).then((res: any) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          getSetInterval();
        }
      });
    };

    //倒计时60s
    const getSetInterval = (): void => {
      timeNum.value = 60;
      countDown.value = setInterval(() => {
        setTimeout(() => {
          if (timeNum.value > 0 && timeNum.value <= 60) {
            isShowSend.value = false;
            timeNum.value--;
          } else {
            isShowSend.value = true;
            clearInterval(countDown.value);
          }
        }, 0);
      }, 1000);
    };
    watchEffect(() => {
      if (
        forgetRuleForm.mobile.length == 11 &&
        forgetRuleForm.code.length == 6 &&
        forgetRuleForm.password.length > 5 &&
        forgetRuleForm.password_confirmation.length > 5
      ) {
        isAffirm.value = true;
      } else {
        isAffirm.value = false;
      }
    });

    // 判断是pc端还是移动端
    const windowW = window.innerWidth;
    return {
      send,
      isShowSend,
      timeNum,
      countDown,
      forget,
      forgetRuleForm,
      activeName,
      forgetRules,
      submit,
      isAffirm,
      windowW,
    };
  },
});
