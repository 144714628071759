
import { onBeforeMount, defineComponent, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import sideBar from "@/components/mine/sideBar.vue";
import { Overlay, Button, Dialog } from "vant";
import { ElMessageBox, ElMessage } from "element-plus";

export default defineComponent({
  name: "phoneLogin",
  components: {
    sideBar,
    vanOverlay: Overlay,
    vanButton: Button,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const user = ref<any>({});
    onBeforeMount(() => {
      user.value = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo") as string)
        : {};
    });
    const router = useRouter();
    const route = useRoute();
    const show = ref(false);
    const showPopup = () => {
      show.value = true;
    };
    const loginOut = (): void => {
      Dialog.confirm({
        message: "您确定要退出登录么？",
        className: "loginOut",
        confirmButtonColor: "#999999",
      })
        .then(() => {
          localStorage.removeItem("authorization");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("expirationTime");
          ElMessage({
            type: "success",
            message: "退出成功！",
          });
          if (
            location.origin == "https://pay2.diancang.site" ||
            location.origin == "https://zhishiquan.online"
          ) {
            location.href = "/official-web/";
          } else {
            location.href = "/";
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消退出！",
          });
        });
    };
    watchEffect(() => {
      if (route.name) {
        show.value = false;
      }
    });
    return {
      user,
      show,
      showPopup,
      loginOut,
    };
  },
});
