
import { onBeforeMount, defineComponent, ref, provide } from "vue";
import { Router, useRouter } from "vue-router";
import sideBar from "@/components/mine/sideBar.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import loginDialog from "@/components/loginDialog/index.vue";
export default defineComponent({
  components: {
    sideBar,
    loginDialog,
  },
  setup() {
    const user = ref<any>({});
    const newphone = ref("");
    const status = ref("login");

    onBeforeMount(() => {
      user.value = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo") as string)
        : {};
      if (user.value.mobile) {
        const phone = user.value.mobile.toString();
        newphone.value = phone.replace(phone.substr(3, 4), "****");
      }
    });

    const router = useRouter();
    const toMineSubject = (): void => {
      ElMessageBox.confirm("确定要退出登录么？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "outLoginBox",
      })
        .then(() => {
          localStorage.removeItem("authorization");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("expirationTime");
          ElMessage({
            type: "success",
            message: "退出成功！",
          });
          if (
            location.origin == "https://pay2.diancang.site" ||
            location.origin == "https://zhishiquan.online"
          ) {
            location.href = "/official-web/";
          } else {
            location.href = "/";
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消退出！",
          });
        });
    };
    const isShowDialog = ref(false);
    const login = (val: string) => {
      isShowDialog.value = false;
      setTimeout(() => {
        isShowDialog.value = true;
        status.value = val;
      }, 200);
    };
    return {
      newphone,
      toMineSubject,
      user,
      login,
      isShowDialog,
      status,
    };
  },
});
