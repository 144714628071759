import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67391cad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-dialog" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login = _resolveComponent("login")!
  const _component_forgetPassword = _resolveComponent("forgetPassword")!
  const _component_register = _resolveComponent("register")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.centerDialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.centerDialogVisible) = $event)),
      "show-close": false,
      "before-close": _ctx.handleClose,
      center: "",
      class: "e-dialog"
    }, {
      default: _withCtx(() => [
        (_ctx.status == 'login')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_login)
            ]))
          : (_ctx.status == 'forgetPassword')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_forgetPassword, { onSwitch: _ctx.aa }, null, 8, ["onSwitch"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_register, { onSwitch: _ctx.aa }, null, 8, ["onSwitch"])
              ]))
      ]),
      _: 1
    }, 8, ["modelValue", "before-close"])
  ]))
}