
import {
  reactive,
  ref,
  toRefs,
  defineComponent,
  onBeforeMount,
  watchEffect,
  inject,
} from "vue";
import { ElForm, ElMessage } from "element-plus";
import { passWordSubmit } from "@/api/login";
import { useRouter, useRoute } from "vue-router";
type FormInstance = InstanceType<typeof ElForm>;
export default defineComponent({
  name: "passwordLogin",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const form = ref<FormInstance>();
    const ruleForm2 = reactive({
      mobile: "",
      password: "",
    });
    const checked = ref<boolean>(true);
    const formRoute = localStorage.getItem("formRoute"); //获取上一页路径
    const isAffirm = ref(false);
    onBeforeMount(() => {
      ruleForm2.mobile = localStorage.getItem("mobile")
        ? (localStorage.getItem("mobile") as string)
        : "";
      ruleForm2.password = localStorage.getItem("password")
        ? (localStorage.getItem("password") as string)
        : "";
    });

    const vaildateMobile = (rule: any, value: any, callback: any) => {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const rules2 = reactive({
      mobile: [
        {
          required: true,
          message: "请输入手机号",
          trigger: "blur",
        },
        { validator: vaildateMobile, trigger: "change" },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
      ],
    });

    const submit = (formEl: FormInstance | undefined): void => {
      (formEl as any).validate((valid: any) => {
        if (valid) {
          passWordSubmit(ruleForm2).then((res: any) => {
            if (res.code == 0) {
              if (checked.value) {
                localStorage.setItem("mobile", ruleForm2.mobile);
                localStorage.setItem("password", ruleForm2.password);
              }
              ElMessage.success("登录成功！");

              localStorage.setItem("userInfo", JSON.stringify(res.data.info));
              localStorage.setItem(
                "authorization",
                res.data.info.authorization
              );
              localStorage.setItem("expirationTime", res.data.info.ex_time);
              if (windowW > 850) {
                location.reload();
              } else {
                if (
                  location.origin == "https://pay2.diancang.site" ||
                  location.origin == "https://zhishiquan.online"
                ) {
                  console.log("formRoute", formRoute);
                  if (formRoute) {
                    // router.push({path:route.query.path as string,query:{id:route.query.id}})
                    location.href =
                      location.origin + "/official-web" + formRoute;
                  } else {
                    location.href = "/official-web/";
                  }
                } else {
                  // router.push({path:route.query.path as string,query:{id:route.query.id}})
                  if (formRoute) {
                    location.href = location.origin + formRoute;
                  } else {
                    location.href = "/";
                  }
                }
              }
            }
          });
        }
      });
    };

    const myInject: any = inject("passRegister");
    const passRegister = () => {
      myInject("passRegister");
      console.log("dsdsd");
    };

    const forpaInject: any = inject("forgetPassword");
    const forgetPassword = () => {
      forpaInject("forgetPassword");
    };

    watchEffect(() => {
      if (ruleForm2.mobile.length == 11 && ruleForm2.password.length > 5) {
        isAffirm.value = true;
      } else {
        isAffirm.value = false;
      }
    });
    // 判断是pc端还是移动端
    const windowW = window.innerWidth;
    return {
      windowW,
      ruleForm2,
      form,
      rules2,
      checked,
      submit,
      passRegister,
      isAffirm,
      forgetPassword,
    };
  },
});
