import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import {official_website} from "./baseurl";
import { setUrl } from "@/utils/common";
//首页路由接口
export const routerList = (data?:object)=>{
    return service({
        method: "get",
        url: `${official_website}/v1/homepage/top_column_list`,
        data,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}


//首页视频封面
export const website_cover = (data:object)=>{
    return service({
        method: "get",
        url: `${official_website}/v1/homepage/website_cover`,
        headers: {
            // authorization: localStorage.userInfo
            //     ? JSON.parse(localStorage.userInfo).info.authorization
            //     : "",
        },
        params: data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

//首页页面全部数据
export const column_category_list = async (data:object)=>{
    const res = await service({
        method: "get",
        url: `${official_website}/v1/homepage/column_category_list`,
        headers: {},
        params: data
    });
    return res;
}

