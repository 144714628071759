
import {
  onBeforeMount,
  defineComponent,
  ref,
  provide,
  watchEffect,
  Ref,
} from "vue";
import login from "@/views/login/index.vue";
import register from "@/views/login/register.vue";
import forgetPassword from "@/views/login/forgetPassword.vue";

export default defineComponent({
  components: {
    login,
    register,
    forgetPassword,
  },
  props: {
    isShowDialog: {
      type: Boolean,
      default: () => false,
    },
    status: {
      type: String,
      default: () => "login",
    },
  },
  setup(props) {
    const centerDialogVisible = ref(false);
    const status = ref("login");
    const aa = (val: string) => {
      status.value = val;
      centerDialogVisible.value = true;
    };

    const register2 = (val: any) => {
      status.value = val;
      console.log(val);
    };
    const passRegister = (val: string) => {
      status.value = val;
    };
    const forgetPassword = (val: string) => {
      status.value = val;
    };
    const login = (val: string) => {
      status.value = val;
    };

    provide("login", login);
    provide("register", register2);
    provide("passRegister", passRegister);
    provide("forgetPassword", forgetPassword);
    watchEffect(() => {
      centerDialogVisible.value = props.isShowDialog;
      status.value = props.status;
      console.log("centerDialogVisible.value", centerDialogVisible.value);
    });
    return {
      login,
      centerDialogVisible,
      status,
      aa,
      register2,
      forgetPassword,
    };
  },
});
