
import {
  reactive,
  ref,
  toRefs,
  defineComponent,
  onBeforeMount,
  inject,
  watchEffect,
} from "vue";
import { ElForm, ElMessage } from "element-plus";
import { sendCode, mobileLogin } from "@/api/login";
import { useRouter, useRoute } from "vue-router";
type FormInstance = InstanceType<typeof ElForm>;
export default defineComponent({
  name: "shortMessageLogin",
  setup() {
    const isShowSend = ref(true);
    const timeNum = ref(60);
    const countDown = ref();
    const checked = ref(true);
    const router = useRouter();
    const route = useRoute();
    const shortForm = ref<FormInstance>();
    const formRoute = localStorage.getItem("formRoute"); //获取上一页路径
    const isAffirm = ref(false);
    const shortRuleForm = reactive({
      mobile: "",
      code: "",
    });

    onBeforeMount(() => {
      shortRuleForm.mobile = localStorage.getItem("mobile")
        ? (localStorage.getItem("mobile") as string)
        : "";
    });

    const vaildateMobile = (rule: any, value: any, callback: any) => {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const shortRules = reactive({
      mobile: [
        {
          required: true,
          message: "请输入手机号",
          trigger: "blur",
        },
        { validator: vaildateMobile, trigger: "change" },
      ],
      code: [
        {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
        },
      ],
    });

    // 发送验证码
    const send = (): void => {
      sendCode({ mobile: shortRuleForm.mobile }).then((res: any) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          getSetInterval();
        }
      });
    };

    //倒计时60s
    const getSetInterval = (): void => {
      timeNum.value = 60;
      countDown.value = setInterval(() => {
        setTimeout(() => {
          if (timeNum.value > 0 && timeNum.value <= 60) {
            isShowSend.value = false;
            timeNum.value--;
          } else {
            isShowSend.value = true;
            clearInterval(countDown.value);
          }
        }, 0);
      }, 1000);
    };

    const submit = (formEl: FormInstance | undefined): void => {
      (formEl as any).validate((valid: any) => {
        if (valid) {
          mobileLogin(shortRuleForm).then((res: any) => {
            if (res.code == 0) {
              if (checked.value) {
                localStorage.setItem("mobile", shortRuleForm.mobile);
              }
              ElMessage.success(res.msg);
              localStorage.setItem("userInfo", JSON.stringify(res.data.info));
              localStorage.setItem(
                "authorization",
                res.data.info.authorization
              );
              localStorage.setItem("expirationTime", res.data.info.ex_time);
              if (windowW > 850) {
                location.reload();
              } else {
                if (
                  location.origin == "https://pay2.diancang.site" ||
                  location.origin == "https://zhishiquan.online"
                ) {
                  console.log("formRoute", formRoute);
                  if (formRoute) {
                    location.href =
                      location.origin + "/official-web" + formRoute;
                  } else {
                    location.href = "/official-web/";
                  }
                } else {
                  if (formRoute) {
                    location.href = location.origin + formRoute;
                  } else {
                    location.href = "/";
                  }
                }
              }
            }
          });
        }
      });
    };

    const myInject: any = inject("register");

    const toRegister = (val: string) => {
      myInject(val);
    };

    watchEffect(() => {
      if (shortRuleForm.mobile.length == 11 && shortRuleForm.code.length == 6) {
        isAffirm.value = true;
      } else {
        isAffirm.value = false;
      }
    });
    // 判断是pc端还是移动端
    const windowW = window.innerWidth;
    return {
      windowW,
      submit,
      send,
      isShowSend,
      timeNum,
      countDown,
      checked,
      shortForm,
      shortRuleForm,
      shortRules,
      toRegister,
      isAffirm,
    };
  },
});
