import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import {official_website} from "../baseurl";

// 发送验证码
export const sendCode = (data:object)=>{
    return service({ 
        method: "post",
        url: `${official_website}/v1/send`,
        data,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 注册
export const userRegister = (data:object)=>{
    return service({ 
        method: "post",
        url: `${official_website}/v1/register`,
        data,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 密码登录
export const passWordSubmit = (data:object)=>{
    return service({ 
        method: "post",
        url: `${official_website}/v1/mobile_password_login`,
        data,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 忘记密码 
export const forgetPassword = (data:object)=>{
    return service({ 
        method: "post",
        url: `${official_website}/v1/reset_passwd`,
        data,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 验证码登录
export const mobileLogin = (data:object)=>{
    return service({ 
        method: "post",
        url: `${official_website}/v1/mobile_login`,
        data,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
