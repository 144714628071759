<template>
  <div class="pHeader_con">
    <!-- <div class="pHeader_top">
      <van-button class="goBackBtn" @click="$router.go(-1)">
        <img src="@/assets/images/phone/goback.png" alt="" />
      </van-button>
      <van-button class="goMoreBtn"> 更多 </van-button>
    </div> -->
    <div class="pHeader_btm">
      <router-link to="/">
        <van-image
          style="display: flex; align-items: center;width:3.1rem;"
          :src="require('@/assets/images/common/logo.png')"
          :fit="fit"
        />
      </router-link>
      <div class="pHeader_menu">
        <router-link to="/search?search_type=0&search_active=all">
          <van-image
            style="display: flex; align-items: center;width: 0.48rem"
            :src="require('@/assets/images/phone/search.png')"
            :fit="fit"
          />
        </router-link>
        <div class="pHeader_nav" @click="showPopup" id="pHeader_nav">
          <van-image
            style="display: flex; align-items: center; margin-left: 0.4rem;width: 0.32rem"
            :src="require('@/assets/images/phone/menu.png')"
            :fit="fit"
          />
        </div>
        <div class="pHeader_login">
          <phoneLogin />
        </div>
      </div>
    </div>
  </div>
  <van-popup v-model:show="navShow" position="center">
    <phoneNav />
  </van-popup>
</template>

<script>
import phoneLogin from "@/components/phoneHeader/phoneLogin.vue";
import phoneNav from "@/components/phoneHeader/phoneNav.vue";

import { useRoute, useRouter } from "vue-router";
import {
  reactive,
  ref,
  toRefs,
  defineComponent,
  onBeforeMount,
  onUnmounted,
} from "vue";

import { Button, Image, Popup } from "vant";

export default defineComponent({
  name: "phoneHeader",
  setup() {
    const navShow = ref(false);
    const route = useRoute();
    onBeforeMount(() => {
      //跳转路由菜单隐藏
      router.afterEach((to, from, next) => {
        navShow.value = false;
      });
    });
    const router = useRouter();

    const showPopup = () => {
      navShow.value = true;
    };
    return {
      navShow,
      showPopup,
      route,
    };
  },
  components: {
    phoneLogin,
    phoneNav,
    vanButton: Button,
    vanImage: Image,
    vanPopup: Popup,
  },
});
</script>
<style>
/* .van-overlay {
  opacity: 0;
} */
.van-popup {
  right: 0.32rem;
  top: 1rem;
  left: auto;
  transform: translate(0, 0);
  border-radius: 4px;
  width: 3.2rem;
}
</style>
<style lang="less" scoped>
@import url("@/assets/styles/common.less");
.pHeader_con {
  width: 100%;
  .goBackBtn{
    img{
      width: 0.44rem;
    }
  }
  .pHeader_top {
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    height: 0.88rem;
    line-height: 0.88rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.32rem;
    .goMoreBtn{
      font-size: 0.32rem;
    }
  }
  .pHeader_btm {
    background: #191c29;
    height: 1rem;
    line-height: 1rem;
    box-sizing: border-box;
    padding: 0 0.32rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pHeader_menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .pHeader_login {
      margin-left: 0.4rem;
    }
  }
}
</style>