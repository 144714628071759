import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-131d0696"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "phoneNav-index" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      "default-active": '/' + _ctx.activeIndex2,
      class: "el-menu-vertical-demo",
      "text-color": "#666666"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item, index) => {
          return (_openBlock(), _createElementBlock("a", {
            key: index,
            href: item.index,
            class: _normalizeClass(item.isSelected ? 'isSelected uuu' : '')
          }, [
            _createVNode(_component_el_menu_item, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.lable), 1)
              ]),
              _: 2
            }, 1024)
          ], 10, _hoisted_2))
        }), 128))
      ]),
      _: 1
    }, 8, ["default-active"])
  ]))
}