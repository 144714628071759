
import router from "@/router";
import { defineComponent,onBeforeMount,ref, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "sideBar",
  setup(){
    const activeRouter = ref();
    const route = useRoute()
    watchEffect(()=>{
      activeRouter.value = route.path;
    })
    return{
      activeRouter
    }
  }
  

});
