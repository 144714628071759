
import { reactive, ref, toRefs, defineComponent, onBeforeMount } from "vue";
import { routerList } from "@/api/index";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "phoneNav",
  setup() {
    const menuList = ref();
    const route = useRoute();
    const activeIndex2 = ref("/");

    onBeforeMount(() => {
      activeIndex2.value = route.meta.index as string;
      routerList().then((res: any) => {
        if (res.code == 0) {
          menuList.value = res.data;
          menuList.value.forEach((item: any) => {
            item.isSelected = false;
            if (activeIndex2.value == item.index) {
              item.isSelected = true;
            }
          });
        }
      });
    });

    return {
      activeIndex2,
      menuList,
    };
  },
});
