
import sideBar from "@/components/mine/sideBar.vue";
import { defineComponent, onBeforeMount, ref, reactive, toRefs } from "vue";
export default defineComponent({
  name: "index",
  components: {
    sideBar,
  },
  setup() {
    const user = ref<any>({});
    onBeforeMount(() => {
      user.value = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo") as string)
        : {};
    });
    return {
      user,
    };
  },
});
