
import { routerList } from '@/api/index';
import { reactive, ref, toRefs,defineComponent, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  // name:'courceDetails',、
  setup() {
    const activeIndex2 = ref("/");
    const routerData = ref<Array<any>>([])
    const menu = reactive({
      menuList: [
        {
          id: 0,
          type: "item",
          index: "/",
          lable: "首页",
          icon: "el-icon-circle-plus-outline",
          route: {
            type: "inner",
            path: { name: "KgGainIframe", params: { postfix: "D2R" } },
          },
        },
        {
          id: 1,
          type: "submenu",
          index: "",
          lable: "全部节目",
          icon: "ic-association-analysis",
          childs: [
            {
              id: 0,
              // type: "item",
              index: "/basicCourse",
              lable: "100+门通识基础课",
              // route: {
              //   type: "inner",
              //   path: { name: "basicCourse" },
              // },
            },
            {
              id: 1,
              type: "item",
              index: "classicGuide",
              lable: "100+本人类经典导读",
              route: {
                type: "inner",
                path: { name: "classicGuide" },
              },
            },
            {
              id: 2,
              type: "item",
              index: "famousTeacher",
              lable: "200+位名师",
              route: {
                type: "inner",
                path: { name: "famousTeacher" },
              },
            },
            {
              id: 3,
              type: "item",
              index: "subject",
              lable: "学科入门",
              route: {
                type: "inner",
                path: { name: "subject" },
              },
            },
            {
              id: 4,
              type: "item",
              index: "world",
              lable: "看世界",
              route: {
                type: "inner",
                path: { name: "world" },
              },
            },
            {
              id: 5,
              type: "item",
              index: "headLine",
              lable: "知鸦头条",
              route: {
                type: "inner",
                path: { name: "headLine" },
              },
            },
            
          ],
        },
        {
          id: 11,
          type: "submenu",
          index: "about",
          lable: "关于我们",
          icon: "el-icon-circle-plus-outline",
          // route: {
          //   type: "inner",
          //   path: { name: "KgGainIframe", params: { postfix: "D2R" } },
          // },
          childs: [
            {
              id: 10,
              type: "item",
              index: "contenUs",
              lable: "联系我们",
              icon: "el-icon-circle-plus-outline",
              route: {
                type: "inner",
                path: { name: "KgGainIframe", params: { postfix: "D2R" } },
              },
            },
            {
              id: 10,
              type: "item",
              index: "joinUS",
              lable: "加入我们",
              icon: "el-icon-circle-plus-outline",
              route: {
                type: "inner",
                path: { name: "KgGainIframe", params: { postfix: "D2R" } },
              },
            },
          ],
        },
      ],
    })
    const route = useRoute()

    onBeforeMount(()=>{
      activeIndex2.value = (route.meta.index as string)
      routerList().then((res:any)=>{
        if(res.code == 0){
        menu.menuList[1].childs = res.data;
        }
      })
    })

    const handleSelect = (key: string, keyPath: string[]) => {
      console.log(key, keyPath);
    };
    
    return {
      activeIndex2,
      ...toRefs(menu),
    }
  }
})

