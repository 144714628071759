import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9487180e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header_menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      "default-active": _ctx.activeIndex2,
      class: "el-menu-demo",
      mode: "horizontal",
      router: "true",
      "unique-opened": "true",
      "background-color": "none",
      "active-text-color": "#FFCC00",
      onSelect: _ctx.handleSelect
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (item.type == 'item')
              ? (_openBlock(), _createBlock(_component_el_menu_item, {
                  key: 0,
                  index: item.index
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.lable), 1)
                  ]),
                  _: 2
                }, 1032, ["index"]))
              : (item.type == 'submenu')
                ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                    key: 1,
                    index: index,
                    "popper-class": "sub_menu",
                    "popper-append-to-body": "true"
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.lable), 1)
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childs, (itemChild, indexChild) => {
                        return (_openBlock(), _createBlock(_component_el_menu_item, {
                          key: indexChild,
                          index: itemChild.index
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(itemChild.lable), 1)
                          ]),
                          _: 2
                        }, 1032, ["index"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["default-active", "onSelect"])
  ]))
}