<template>
  <div class="header">
    <div class="header_con x1446">
      <router-link to="/">
        <div class="header_logo">
          <el-image
            style="display: flex; align-items: center;max-width:200px;"
            :src="require('@/assets/images/common/logo.png')"
            :fit="fit"
          ></el-image>
        </div>
      </router-link>
      <div class="header_nav">
        <headerNav />
      </div>
      <div class="header_search" v-if="route.name != 'search'">
        <headerSearch />
      </div>
      <div class="header_login">
        <loginStatus />
      </div>
    </div>
  </div>
  <div class="phone_header">
    <phoneHeader />
  </div>
</template>

<script>
import headerNav from "@/components/header/headerNav.vue";
import headerSearch from "@/components/header/headerSearch.vue";
import loginStatus from "@/components/header/loginStatus.vue";
import phoneHeader from "@/components/phoneHeader/phoneHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { reactive, ref, toRefs, defineComponent, onBeforeMount } from "vue";
export default defineComponent({
  name: "headerMenu",
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
    });
    return { route };
  },
  components: {
    headerNav,
    headerSearch,
    loginStatus,
    phoneHeader,
  },
});
</script>
<style lang="less" scoped>
@import url("@/assets/styles/common.less");
.header {
  width: 100%;
  height: 72px;
  background: #191c29;
  line-height: 72px;
}
.header_con {
  width: @width_default;
  height: 100%;
  margin: 0 auto;
}
.header_nav,
.header_logo,
.header_search {
  display: flex;
  align-items: center;
  float: left;
  height: 100%;
}
.header_login {
  float: right;
}

.logo_span1 {
  width: 90px;
  color: #fff;
  font-size: 20px;
}
.logo_span2 {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Thin;
  font-weight: 200;
  color: #b0b1b5;
  width: 160px;
}
.header_search {
  margin-left: 70px;
}
.phone_header{
    display: none;
  }

@media only screen and (max-width:850px) {
  .header {
    display: none;
  }
  .phone_header{
    display: block;
  }
}
</style>