import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import router from "@/router";
import { ElLoading } from 'element-plus'
import store from '@/store/index';
import { ElMessage } from 'element-plus'

interface PendingType {
    url?: string;
    method?: Method;
    params: any;
    data: any;
    cancel: Function;
}

const pendingList: Array<PendingType> = [];
const CancelToken = axios.CancelToken;

//取消重复请求
const removePending = (config: AxiosRequestConfig): void => {
    for (const key in pendingList) {
        const item: number = +key;
        const list: PendingType = pendingList[key];

        // 当前请求在数组中存在时执行函数体
        if (
            list.url === config.url &&
            list.method === config.method &&
            JSON.stringify(list.params) === JSON.stringify(config.params) &&
            JSON.stringify(list.data) === config.data
        ) {
            // 执行取消操作
            // list.cancel("操作太频繁，请稍后再试");
            // 从数组中移除记录
            pendingList.splice(item, 1);
        }
    }
};
const token: string | any = localStorage.getItem('authorization') ? localStorage.getItem('authorization') : "";
let loading: any = null;

const service = axios.create({
    timeout: 120000,
    headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json; charset=utf-8",
        // "request-id": `01${RondomPass(10)}`,
        authorization: token,
        platform: "web",
        appversion: "web",
        imei: "web",
        phonemodel: "web",
        systemversion: "web",
    },
});

service.interceptors.request.use(
    (config: any): Promise<AxiosRequestConfig> | AxiosRequestConfig => {
        removePending(config);
        loading = ElLoading.service({ fullscreen: true, text: '',background:'transparent' })
        config.cancelToken = new CancelToken((c): void => {
            pendingList.push({
                url: config.url,
                method: config.method,
                params: config.params,
                data: config.data,
                cancel: c,
            });
        });

        return config;
    },
    (error: Error): Promise<never> => {
        setTimeout(() => {
            loading.close()
        }, 800)
        return Promise.reject(error);

    }
);

service.interceptors.response.use(
    (
        response: AxiosResponse
    ): AxiosResponse<object> | Promise<AxiosResponse<object>> => {
        // 先行删除再取消loading否则loading会一直存在
        removePending(response.config);
        if (pendingList.length == 0) {
            setTimeout(() => {
                loading.close()
            }, 800)
        }
        const { data, code, msg } = response.data;

        //token 过期退出去登陆页
        if (code == 401) {
            localStorage.clear();
            ElMessage.error('请求超时,请重新登陆')
            // setTimeout((): void => {
            //     router.push({ name: "login" });
            // }, 1500);
        }

        if (code !== 0) {
            //没有token
            if (code == 100401) {
                ElMessage.error('您还有登录，请先登录！')
            } else {
                ElMessage.error(msg)
            }
        }
        setTimeout(() => {
            loading.close()
        }, 800)
        return Promise.resolve(response.data);
    },
    (error: Error): Promise<never> => {
        // 判断请求异常信息中是否含有超时timeout字符串;
        if (error.message && error.message.includes("timeout") || error.message.includes("401")) {
            ElMessage.error('请求超时,请重新登陆')
            localStorage.clear();
            setTimeout((): void => {
                router.push({ name: "login" });
            }, 1000);
        } else {
            ElMessage.error('请求超时' + error.message)
        }
        setTimeout(() => {
            loading.close()
        }, 800)
        return Promise.reject(error);
    }
);
export { axios, service };
