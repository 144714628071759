<template>
  <div class="layout">
    <headerMenu />
    <mineIndex />
    <footerM />
  </div>
</template>
<script>
import headerMenu from "@/components/header/headerMenu.vue";
import footerM from "@/components/footer/footer.vue";
import mineIndex from "@/views/mine/index.vue"
export default {
  setup () {
  },
  components: {
    headerMenu,
    footerM,
    mineIndex,
  },

}

</script>
<style lang="scss" scoped>
@import "@/assets/styles/normalize.css";
.layout {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
@media only screen and (max-width: 1200px) {
  .layout {
    width: 1200px;
  }
}
@media only screen and (max-width: 850px) {
  .layout {
    width: 100%;
  }
}
</style>


