
import { reactive, ref, toRefs, defineComponent, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import passwordLogin from "@/components/login/passwordLogin.vue";
import shortMessageLogin from "@/components/login/shortMessageLogin.vue";
export default defineComponent({
  name: "login",
  components: {
    passwordLogin,
    shortMessageLogin,
  },
  setup() {
    const activeName = ref<string>("shortMessage");
    const isMobile = ref(true)
    onBeforeMount(()=>{
      if(navigator.userAgent.match(/mobile/i)){
        isMobile.value = false;
      }
      console.log(isMobile.value);
      
    })

    return {
      activeName,
      isMobile
    };
  },
});
