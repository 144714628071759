
import { ref, defineComponent, watchEffect } from "vue";
import { Search } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "search",
  setup() {
    const searchInput = ref("");
    const searchName = ref("");
    const router = useRouter();
    watchEffect(() => {
      searchName.value = searchInput.value;
    });
    const handleClick = (): void => {
      router.push({
        path: "/search",
        name: "search",
        query: {
          search_name: searchName.value,
          search_type: 0,
          search_active: "all",
        },
      });
    };
    return {
      searchInput,
      handleClick,
      Search,
    };
  },
});
