import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    modelValue: _ctx.searchInput,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInput) = $event)),
    class: "w-50 m-2",
    placeholder: "｜输入关键词",
    "suffix-icon": _ctx.Search,
    "input-style": "{width:240px;height:42px;background:#222531;border:1px solid #555555;border-radius:34px;color:#fff;}",
    onChange: _ctx.handleClick
  }, null, 8, ["modelValue", "suffix-icon", "onChange"]))
}