
import { useRouter, useRoute, LocationQueryValue } from "vue-router";
export default {
  name: "appMain",
  setup() {
    // 判断是pc端还是移动端
    const windowW = window.innerWidth;
    return {
      windowW,
    };
  },
};
