
import {
  reactive,
  ref,
  toRefs,
  defineComponent,
  onMounted,
  onBeforeMount,
  watchEffect,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import type { ElForm } from "element-plus";
import { sendCode, userRegister } from "@/api/login/index";
import { ElMessage } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  name: "register",

  setup({}, { emit }) {
    const router = useRouter();
    const isShowSend = ref(true);
    const timeNum = ref(60);
    const countDown = ref();
    const ruleFormRef = ref<FormInstance>();
    const server = ref<Boolean>(true);
    const ruleForm = reactive({
      mobile: "",
      code: "",
      password: "",
      password_confirmation: "",
    });
    const isMobile = ref(false);
    const activeName = ref("register");
    const isAffirm = ref(false);
    onBeforeMount(() => {
      if (navigator.userAgent.match(/mobile/i)) {
        isMobile.value = true;
      }
    });

    onMounted(() => {});
    const vaildateMobile = (rule: any, value: any, callback: any) => {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const rules = reactive({
      mobile: [
        {
          required: true,
          message: "请输入手机号",
          trigger: "blur",
        },
        { validator: vaildateMobile, trigger: "change" },
      ],
      password: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
      ],
      code: [
        {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
        },
      ],
      password_confirmation: [
        {
          required: true,
          message: "请再次输入密码",
          trigger: "blur",
        },
      ],
    });

    const login = (): void => {
      router.push({ name: "login" });
    };
    const send = (): void => {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(ruleForm.mobile)) {
        ElMessage({
          type: "error",
          message: "请输入正确的手机号",
        });
        return;
      }
      sendCode({ mobile: ruleForm.mobile }).then((res: any) => {
        if (res.code == 0) {
          ElMessage.success(res.msg);
          getSetInterval();
        }
      });
    };

    //倒计时60s
    const getSetInterval = (): void => {
      timeNum.value = 60;
      countDown.value = setInterval(() => {
        setTimeout(() => {
          if (timeNum.value > 0 && timeNum.value <= 60) {
            isShowSend.value = false;
            timeNum.value--;
          } else {
            isShowSend.value = true;
            clearInterval(countDown.value);
          }
        }, 0);
      }, 1000);
    };

    const submit = (formEl: FormInstance | undefined): void => {
      (formEl as any).validate((valid: any) => {
        if (valid) {
          console.log(ruleForm);
          let data = Object.assign(ruleForm, {
            consent_agreement: server.value ? 1 : 0,
          });
          userRegister(data).then((res: any) => {
            if (res.code == 0) {
              ElMessage.success("注册成功");
              if (windowW > 850) {
                emit("switch", "login");
              } else {
                router.push({ name: "login" });
              }
            }
          });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };

    const switchLogin = () => {
      emit("switch", "login");
    };
    watchEffect(() => {
      if (
        ruleForm.mobile.length == 11 &&
        ruleForm.code.length == 6 &&
        ruleForm.password.length > 5 &&
        ruleForm.password_confirmation.length > 5
      ) {
        isAffirm.value = true;
      } else {
        isAffirm.value = false;
      }
    });
    // 判断是pc端还是移动端
    const windowW = window.innerWidth;
    return {
      windowW,
      login,
      switchLogin,
      rules,
      isShowSend,
      timeNum,
      countDown,
      ...toRefs(ruleForm),
      ruleFormRef,
      ruleForm,
      server,
      submit,
      send,
      isMobile,
      activeName,
      isAffirm,
    };
  },
});
