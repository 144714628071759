import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      (_openBlock(), _createBlock(_KeepAlive, { include: "Home" }, [
        ($setup.windowW < 850)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
          : _createCommentVNode("", true)
      ], 1024)),
      ($setup.windowW > 850)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}