<template>
  <div class="footer">
    <div class="footer_con">
      <div class="footer_top">
        <div class="footer_name footer_com">
          <h4 class="common_h4">北京小鸦科技有限公司</h4>
          <el-image
            class="name_img"
            style="width: 135px"
            :src="require('@/assets/images/common/logo_foot.png')"
          ></el-image>
        </div>
        <div class="footer_serve footer_com">
          <h4 class="common_h4">关于知鸦通识</h4>
          <router-link to="/serviceAgree" class="serve_a">服务协议</router-link>
          <router-link to="/intimityPolicy" class="serve_a"
            >隐私政策</router-link
          >
          <router-link to="/contenUs" class="serve_a">联系我们</router-link>
        </div>
        <div class="footer_service footer_com">
          <h4 class="common_h4">客服</h4>
          <p class="service_p">
            官方微信<span>知鸦通识</span>
          </p>
          <p class="service_p">联系电话<span>13651183194</span></p>
        </div>
        <div class="footer_code">
          <div class="code_com">
            <h4 class="common_h4">关注我们</h4>
            <el-image
              class="code_img"
              :src="require('@/assets/images/common/codeGZ.jpg')"
            ></el-image>
          </div>
          <div class="code_com">
            <h4 class="common_h4">知鸦通识app</h4>
            <el-image
              class="code_img"
              :src="require('@/assets/images/common/logoapkercode.png')"
            ></el-image>
          </div>
        </div>
      </div>
      <div class="footer_btm">
        &copy;1997-2019北京小鸦科技有限公司版权所有｜侵权必究｜<a href="https://beian.miit.gov.cn" target="_blank">京ICP备17065032号-12</a>
        ｜ <span @click="openImg">增值电信业务经营许可证：京B2-20181652</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, defineComponent, onBeforeMount } from "vue";
export default defineComponent({
  name: "footer",
  setup() {
    const openImg = () => {
      window.open(
        "https://zmbai.oss-cn-beijing.aliyuncs.com/uploads/jpeg/20220107/2bda2836bfff5ae1a98cb4c0950281ac.jpeg"
      );
    };

    return {
      openImg,
    };
  },
});
</script>

<style lang="less">
@import "@/assets/styles/common.less";
.footer {
  background: #191c29;
  width: 100%;
  .common_h4 {
    font-size: 18px;
    color: #fafafa;
  }
  .footer_con {
    width: 1200px;
    margin: 0 auto;
    .Padding(40px,0,0,40px);
    .footer_top {
      border-bottom: 1px solid #56575e;
      padding-bottom: 40px;
      .footer_com {
        float: left;
      }
      .footer_name {
        .name_img {
          margin-top: 50px;
        }
      }
      .footer_serve {
        margin-left: 140px;
        .serve_a {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-top: 20px;
          display: block;
          &:hover {
            color: #fff;
          }
        }
      }
      .footer_service {
        margin-left: 90px;
        .service_p {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-top: 20px;
          display: block;
          span {
            margin-left: 20px;
          }
        }
      }
      .footer_code {
        float: right;
        .code_com {
          margin-left: 90px;
          display: inline-block;
          .code_img {
            margin-top: 20px;
            width: 100px;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }
    .footer_btm {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #999999;
      margin-top: 22px;
      span {
        .pointer();
      }
      a {
        color: #999999;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .footer {
    .footer_con {
      width: 90%;
      .footer_top {
        .footer_code {
          .code_com {
            margin-left: 50px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .footer {
    .common_h4 {
    font-size: 0.26rem;
  }
    .footer_con {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      .footer_top {
        border-bottom: 1px solid #56575e;
        padding-bottom: 0.3rem;
        padding: 0.8rem 0.3rem 0.3rem;
        .footer_com {
          float: left;
          height: 1.8rem;
        }
        .footer_name {
          display: none;
        }
        .footer_serve {
          margin-left: 0;
          .serve_a {
            font-size: 0.26rem;
            font-weight: 400;
            color: #999999;
            margin-top: 0.12rem;
            display: block;
            &:hover {
              color: #fff;
            }
          }
        }
        .footer_service {
          margin-left: 0.4rem;
          float: right;
          .service_p {
            font-size: 0.26rem;
            font-weight: 400;
            color: #999999;
            margin-top: 0.12rem;
            display: block;
            span {
              margin-left: 0.3rem;
            }
          }
        }
        .footer_code {
          float: left;
          margin-top: 0.6rem;
          .code_com {
            margin-left: 0.6rem;
            display: inline-block;
            .code_img {
              margin-top: 0.3rem;
              width: 1.8rem;
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }
        &::after {
          content: "";
          display: block;
          clear: both;
        }
      }
      .footer_btm {
        font-size: 0.24rem;
        line-height: 0.34rem;
        font-weight: 400;
        text-align: center;
        color: #999999;
        margin-top: 0;
        padding: 0.26rem 0.3rem 0.8rem;
        box-sizing: border-box;
        span {
          .pointer();
        }
      }
    }
  }
}
</style>