import { createStore } from 'vuex'

export default createStore({
  state: {
    spinning:false,
    userInfo:{}
  },
  mutations: {
    setUserInfo(state,userInfo){
      state.userInfo = userInfo
    }
  },
  actions: {
  },
  modules: {
  }
})
