import * as dd from "dingtalk-jsapi";
const metaTitle = {
    // 设置导航栏标题
    changeDocumentTitle(title: any) {
        // 钉钉浏览器内部，使用钉钉提供的JSAPI中的接口dd.biz.navigation.setTitle设置导航栏标题
        if (window.navigator.userAgent.includes("DingTalk")) {
            dd.biz.navigation.setTitle({
                title,
            })
        } else { document.title = title };//其他浏览器
    }
}
export default metaTitle;
