<template>
  <div class="layout">
    <headerMenu />
    <!-- 移动端控制页面底部展示 -->
    <appMain v-if="windowW > 850" />
    <div class="phContain" v-else>
      <appMain />
    </div>
    <footerM />
  </div>
</template>
<script>
import headerMenu from "@/components/header/headerMenu.vue";
import appMain from "@/components/header/appMain.vue";
import footerM from "@/components/footer/footer.vue";
export default {
  setup() {
    // 判断是pc端还是移动端
    const windowW = window.innerWidth;
    return {
      windowW,
    };
  },
  components: {
    headerMenu,
    appMain,
    footerM,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/normalize.css";

.el-message--error,
.el-message--success,
.el-message--info,
.el-message--warning {
  font-size: 16px;
}
// .el-loading-mask{
//   background: white !important;
// }
.el-loading-spinner {
  color: transparent;
}
.el-loading-spinner .circular .path {
  stroke: #191c29 !important;
}
.el-loading-text {
  color: #191c29 !important;
}
body{
  padding-right: 0 !important;
}

@media only screen and (max-width: 850px) {
  .layout {
    width: 100%;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
<style scoped  lang="scss">
.layout {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: #25293a;
}
@media only screen and (max-width: 1200px) {
  .layout {
    width: 1200px;
  }
}
@media only screen and (max-width: 850px) {
  .layout {
    width: 100%;
    height: auto;
    .phContain {
      // min-height: calc(100vh - 1rem);
    }
  }
}
</style>