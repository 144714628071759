
import { onBeforeMount } from "vue";
import {
  useRouter,
  useRoute,
  LocationQueryValue,
  onBeforeRouteLeave,
} from "vue-router";
export default {
  name: "appMain",
  setup() {
    const router = useRouter();
    onBeforeMount(() => {
      //跳转路由页面滚动到顶部
      router.afterEach((to, from, next) => {
        window.scrollTo(0, 0);
      });
      // 手机端访问跳转到旧版手机
      // if(navigator.userAgent.match(/mobile/i)){
      //   // location.href = location.origin + '/mobile/index'
      //   location.href = 'https://liberal-edu.cn/mobile/index'
      // }

      
    });

  },
};
