import { createRouter, createWebHistory, RouteRecordRaw, onBeforeRouteLeave } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import layout from '@/views/layout/index.vue'
import mineLayout from '@/views/mine/layout/index.vue'
import { nextTick } from 'vue'
import { ElMessageBox, ElMessage } from "element-plus";
import metaTitle from "@/utils/metaTitle";
import "@/assets/styles/elements.less";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: "index",
    component: layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () =>
          import('@/views/home/index.vue'),
        meta: {
          title: '知鸦通识 - 做一个更好的人',
          needLogin: false,
          index: ''
        },
      },
      // 基础课
      {
        path: '/basicCourse',
        name: 'basicCourse',
        component: () =>
          import('@/views/basicCourse/index.vue'),
        meta: {
          title: '100+门通识基础课',
          needLogin: false,
          index: 'basicCourse'
        },
      },
      {
        path: '/courceDetails',
        name: 'courceDetails',
        component: () =>
          import('@/views/basicCourse/courceDetails.vue'),
        meta: {
          title: '100+门通识基础课',
          needLogin: false,
          index: 'courceDetails'
        },
      },
      {
        path: '/videoPlay',
        name: 'videoList',
        component: () =>
          import('@/components/videoList/videoList.vue'),
        meta: {
          title: '视频播放',
          needLogin: false,
          index: 'videoPlay'
        },
      },
      // 经典导读
      {
        path: '/classicGuide',
        name: 'classicGuide',
        component: () =>
          import('@/views/classicGuide/index.vue'),
        meta: {
          title: '100+本人类经典导读',
          needLogin: false,
          index: 'classicGuide'
        },
      },
      {
        path: '/classicDetails',
        name: 'classicDetails',
        component: () =>
          import('@/views/classicGuide/classicDetails.vue'),
        meta: {
          title: '100+本人类经典导读',
          needLogin: false,
          index: 'classicDetails'
        },
      },
      // 学科入门
      {
        path: '/subject',
        name: 'subject',
        component: () =>
          import('@/views/subject/index.vue'),
        meta: {
          title: '学科入门',
          needLogin: false,
          index: 'subject'
        },
      },
      {
        path: '/subjectDetails',
        name: 'subjectDetails',
        component: () =>
          import('@/views/subject/subjectDetails.vue'),
        meta: {
          title: '学科入门',
          needLogin: false,
          index: 'subjectDetails'
        },
      },

      // 200+名师
      {
        path: '/famousTeacher',
        name: 'famousTeacher',
        component: () =>
          import('@/views/famousTeacher/index.vue'),
        meta: {
          title: '200+位名师',
          needLogin: false,
          index: 'famousTeacher'
        },
      },
      // 名师详情页
      {
        path: '/teacherIntro',
        name: 'teacherIntro',
        component: () =>
          import('@/views/famousTeacher/teacherIntro.vue'),
        meta: {
          title: '名师简介',
          needLogin: false,
          index: 'teacherIntro'
        },
      },
      {
        path: '/articleDetails',
        name: 'articleDetails',
        component: () =>
          import('@/views/famousTeacher/articleDetails.vue'),
        meta: {
          title: '文萃详情',
          needLogin: false,
          index: 'articleDetails'
        },
      },
      {
        path: '/readme',
        name: 'readme',
        component: () =>
          import('@/views/famousTeacher/readme.vue'),
        meta: {
          title: '名师自述',
          needLogin: false,
          index: 'readme'
        },
      },


      //知鸦头条
      {
        path: '/headLine',
        name: 'headLine',
        component: () =>
          import('@/views/headLine/index.vue'),
        meta: {
          title: '知鸦头条',
          needLogin: false,
          index: 'headLine'
        },
      },
      {
        path: '/headLineDetail',
        name: 'headLineDetail',
        component: () =>
          import('@/views/headLine/detail.vue'),
        meta: {
          title: '知鸦头条详情',
          needLogin: false,
          index: 'headLineDetail'
        },
      },
      //看世界
      {
        path: '/world',
        name: 'world',
        component: () =>
          import('@/views/world/index.vue'),
        meta: {
          title: '看世界',
          needLogin: false,
          index: 'world'
        },
      },
      {
        path: '/detail',
        name: 'worldDetail',
        component: () =>
          import('@/views/world/detail.vue'),
        meta: {
          title: '看世界详情',
          needLogin: false,
          index: 'detail'
        },
      },
      {
        path: '/worldAnswer',
        name: 'worldAnswer',
        component: () =>
          import('@/views/world/answer.vue'),
        meta: {
          title: '看世界解读',
          needLogin: false,
          index: 'worldAnswer'
        },
      },
      // 联系我们
      {
        path: '/contenUs',
        name: 'contenUs',
        component: () =>
          import('@/views/contenUs/index.vue'),
        meta: {
          title: '联系我们',
          needLogin: false,
          index: 'contenUs'
        },
      },
      {
        path: '/joinUS',
        name: 'joinUS',
        component: () =>
          import('@/views/contenUs/joinUS.vue'),
        meta: {
          title: '加入我们',
          needLogin: false,
          index: 'joinUS'
        },
      },
      {
        path: '/serviceAgree',
        name: 'serviceAgree',
        component: () =>
          import('@/views/contenUs/serviceAgree.vue'),
        meta: {
          title: '服务协议',
          needLogin: false,
          index: 'serviceAgree'
        },
      },
      {
        path: '/intimityPolicy',
        name: 'intimityPolicy',
        component: () =>
          import('@/views/contenUs/intimityPolicy.vue'),
        meta: {
          title: '隐私政策',
          needLogin: false,
          index: 'intimityPolicy'
        },
      },
      // 搜索
      {
        path: '/search',
        name: 'search',
        component: () =>
          import('@/views/search/index.vue'),
        meta: {
          title: '搜索',
          needLogin: false,
          index: 'search'
        },

      },
      {
        path: '/settleAccounts',
        name: 'settleAccounts',
        component: () =>
          import('@/views/mine/shoppingCart/settleAccounts.vue'),
        meta: {
          title: '去结算',
          needLogin: true,
          index: 'settleAccounts'
        },
      },
      {
        path: '/payment',
        name: 'payment',
        component: () =>
          import('@/views/mine/shoppingCart/payment.vue'),
        meta: {
          title: '支付',
          needLogin: true,
          index: 'payment'
        },
      },
    ]
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register.vue'),
    meta: {
      title: '注册',
      needLogin: false,
      index: 'register'
    },
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
      needLogin: false,
      index: 'login'
    },
  },
  // 忘记密码
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('@/views/login/forgetPassword.vue'),
    meta: {
      title: '忘记密码',
      needLogin: false,
      index: 'forgetPassword'
    },
  },
  // 空白
  {
    path: '/null',
    name: 'null',
    component: () => import('@/views/null/index.vue'),
    meta: {
      title: 'null',
      needLogin: false,
      index: 'null'
    },
  },
  // 购物车
  {
    path: '/mineSuject',
    component: mineLayout,
    children: [
      {
        path: '/mineSubject',
        name: 'mineSubject',
        component: () =>
          import('@/views/mine/mineSubject/index.vue'),
        meta: {
          title: '我的课程',
          needLogin: true,
          index: 'mineSubject'
        },
      },
      {
        path: '/shoppingCart',
        name: 'shoppingCart',
        component: () =>
          import('@/views/mine/shoppingCart/index.vue'),
        meta: {
          title: '购物车',
          needLogin: true,
          index: 'shoppingCart'
        },
      },
      {
        path: '/mineOrder',
        name: 'mineOrder',
        component: () =>
          import('@/views/mine/mineOrder/index.vue'),
        meta: {
          title: '我的订单',
          needLogin: true,
          index: 'mineOrder'
        },
      },
      {
        path: '/mineAddress',
        name: 'mineAddress',
        component: () =>
          import('@/views/mine/mineAddress/index.vue'),
        meta: {
          title: '收货地址',
          needLogin: true,
          index: 'mineAddress'
        },
      }
    ]
  }
]
// if (/(Android)/i.test(navigator.userAgent)) {
//   alert(navigator.userAgent);
//   if(navigator.userAgent=='Mozilla/5.0 (Linux; Android 11; PDPM00) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/78.0.3904.96 Mobile Safari/537.36'){
//   alert('我是360');
//   const router = createRouter({
//     history: createWebHistory(process.env.BASE_URL),
//     routes,
//     scrollBehavior: () => {
//       history.pushState(null, '', document.URL)
//     }
//   })
//   }
//   else{

//   }
// }
// bro();
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior: () => {
  //   history.pushState(null, '', document.URL)
  // },
  // scrollBehavior(to, from, savedPosition: any) {
  //   if (savedPosition) {
  //     window.scrollTo(0, savedPosition.top);
  //   } else {
  //     window.scrollTo(0, 0);
  //   }
  // },
})



router.beforeEach((to, from, next) => {


  // window.history.pushState(null, '', from.fullPath);
  if (to.name == "login" && from.name != "register" && from.name != "forgetPassword") {
    localStorage.removeItem("formRoute");
    localStorage.setItem("formRoute", from.fullPath);
  }
  if (to.meta.title) {
    metaTitle.changeDocumentTitle(to.meta.title);
    next()
  }

  if (to.meta.needLogin == true && !localStorage.getItem('authorization')) {
    ElMessage({
      message: '您还没有登录，请先登录！',
      type: 'warning',
    })
    setTimeout((): void => {
      if(window.innerWidth>850){
      router.push({ name: "Home" });
      }
      else{
      router.push({ name: "login" });
      }
    }, 1500);
  }
  if (localStorage.getItem('expirationTime') && new Date().getTime() / 1000 > parseInt(localStorage.getItem('expirationTime') as any)) {
    localStorage.removeItem("authorization");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("expirationTime");
    ElMessage({
      message: '您的登录已过期，请重新登录！',
      type: 'warning',
    })
    setTimeout((): void => {
      location.reload();
    }, 1000);
  }

  // nextTick(()=>{
  //   (document.documentElement as any).scrollTo = -0;
  // })
});

export default router

